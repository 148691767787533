// material-ui
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, ButtonBase, Theme, PaletteMode, IconButton } from '@material-ui/core';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { DefaultRootStateProps } from 'types';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import epnsBell from 'assets/images/epns-bell.png';
import epnsBellBall from 'assets/images/epns-bell-ball.png';
import epnsRing from 'assets/images/epns-ring.png';

// project imports
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';
import { MENU_TYPE } from 'store/actions'; // THEME_RTL

// assets
import { IconMenu2 } from '@tabler/icons';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
        }
    },
    boxContainer: {
        width: '228px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    },
    notificationsButton: {
        position: 'relative',
        height: '25px',
        width: '25px',
        marginRight: '16px',
        marginTop: '-2px'
    },
    epnsBellImage: {
        position: 'absolute',
        height: '25px',
        width: '25px',
        filter: theme.palette.mode === 'dark' ? 'grayscale(1) brightness(2.5)' : 'none'
    },
    darkLightButton: {
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.primary.dark
    }
}));

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
    handleLeftDrawerToggle: () => void;
}

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);

    const [navType, setNavType] = React.useState<PaletteMode>(customization.navType);
    useEffect(() => {
        dispatch({ type: MENU_TYPE, navType });
    }, [dispatch, navType]);

    return (
        <>
            {/* logo & toggler button */}
            <div className={classes.boxContainer}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit">
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </div>

            {/* header search */}
            <div className={classes.grow} />
            <div className={classes.grow} />

            <Tooltip
                title={
                    <>
                        <Typography color="inherit">
                            Get notified on EPNS when your account is at risk of liquidation or settlement.
                        </Typography>
                    </>
                }
            >
                <IconButton
                    // eslint-disable-next-line no-return-assign
                    onClick={(e) => window.open('https://app.epns.io/#/channels?channel=0xc04bd413E7d7C277B27FAa27E4b3015801c26914')}
                    color="primary"
                    aria-label="notifications"
                    className={classes.notificationsButton}
                >
                    <img alt="notifications bell" src={epnsBell} className={classes.epnsBellImage} />
                    <img alt="notifications bell" src={epnsBellBall} className={classes.epnsBellImage} />
                    <img alt="notifications bell" src={epnsRing} className={classes.epnsBellImage} />
                </IconButton>
            </Tooltip>

            <IconButton
                onClick={(e) => setNavType(navType === 'light' ? 'dark' : 'light')}
                className={classes.darkLightButton}
                aria-label="add to shopping cart"
            >
                {navType === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
            </IconButton>
            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
