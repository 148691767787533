import { Market } from 'utils/market';
import { Account } from 'utils/account';
import { NOTIONAL_BALANCE_PRECISION, NOTIONAL_RATE_PRECISION, SECONDS_IN_YEAR } from 'utils/constants';
import moment from 'moment';
import { ExchangeRates } from './sdk';

export const NTOKEN_ACCOUNT_IDS = [
    '0x0ace2dc3995acd739ae5e0599e71a5524b93b886',
    '0x18b0fc5a233acf1586da7c199ca9e3f486305a29',
    '0x6ebce2453398af200c688c7c4ebd479171231818',
    '0xabc07bf91469c5450d6941dd0770e6e6761b90d6'
];

export type NToken = {
    id: string;
    name: string;
    symbol: string;
    totalSupply: number;
    pvHaircutPercentage: number;
    integralTotalSupply: number;
    account: Account;
    currency: {
        underlyingSymbol: string;
        id: string;
        symbol: string;
    };
};

export function calculateNTokenNetPresentValue(
    nToken: NToken,
    markets: Market[],
    exchangeRate: ExchangeRates
): { netPresentValue: number; netPresentValueUSD: number; netPresentValuePerNToken: number; netPresentValueUSDPerNToken: number } {
    const cTokenSymbol = nToken.currency.symbol;
    const { assetCashBalance } = nToken.account.balances[0];

    const portfolioValue = nToken.account.portfolio
        .filter((asset) => asset.assetType === 'fCash')
        .map((asset) => {
            // TODO: Verify whether we need maturity or timeToMaturitySeconds
            const market = markets.find(
                (m) => m.totalAssetCash !== '0' && m.currency.symbol === cTokenSymbol && m.maturity === Number(asset.maturity)
            )!;
            if (!market) {
                return 0;
            }
            const correctedTotalfCash = Number(market.totalfCash);

            const totalfCash = correctedTotalfCash / NOTIONAL_BALANCE_PRECISION;
            const totalCash = Number(market.totalAssetCash) / NOTIONAL_BALANCE_PRECISION;
            const fCash = Number(asset.notional) / NOTIONAL_BALANCE_PRECISION;

            const timeToMaturityYears = (market.maturity - moment().unix()) / SECONDS_IN_YEAR;
            const lastImpliedRate = market.lastImpliedRate / NOTIONAL_RATE_PRECISION;

            return (totalfCash + fCash) / Math.exp(timeToMaturityYears * lastImpliedRate) + totalCash * exchangeRate.assetToUnderlying;
        });

    const netPresentValue =
        (Number(assetCashBalance) / NOTIONAL_BALANCE_PRECISION) * exchangeRate.assetToUnderlying + portfolioValue.reduce((a, b) => a + b); // Sum

    const netPresentValueUSD = netPresentValue * exchangeRate.underlyingToUSD;
    const netPresentValueUSDPerNToken = netPresentValueUSD / (nToken.totalSupply / NOTIONAL_BALANCE_PRECISION);
    const netPresentValuePerNToken = netPresentValue / (nToken.totalSupply / NOTIONAL_BALANCE_PRECISION);
    return { netPresentValue, netPresentValueUSD, netPresentValuePerNToken, netPresentValueUSDPerNToken };
}
