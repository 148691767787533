import {
    IconBusinessplan,
    IconExchange,
    IconNotes,
    IconSmartHome,
    IconAmbulance,
    IconMeat,
    IconZoomMoney,
    IconArrowBarToDown,
    IconLockOff,
    IconSquareRoundedLetterV
} from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { OverrideIcon } from 'types';

const icons = {
    IconSmartHome,
    IconNotes,
    IconExchange,
    IconBusinessplan,
    IconAmbulance,
    IconMeat,
    IconZoomMoney,
    IconArrowBarToDown,
    IconLockOff,
    IconSquareRoundedLetterV
};

export interface HomeMenuProps {
    id: string;
    title: React.ReactNode | string;
    type: string;
    children: {
        id: string;
        title: React.ReactNode | string;
        type: string;
        url: string;
        icon: OverrideIcon;
        breadcrumbs: boolean;
    }[];
}

const homepage: HomeMenuProps = {
    id: 'home',
    title: <FormattedMessage id="home" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/',
            icon: icons.IconSmartHome,
            breadcrumbs: false
        },
        {
            id: 'accounts',
            title: <FormattedMessage id="Accounts" />,
            type: 'item',
            url: '/accounts',
            icon: icons.IconNotes,
            breadcrumbs: false
        },
        {
            id: 'transactions',
            title: <FormattedMessage id="Transactions" />,
            type: 'item',
            url: '/transactions',
            icon: icons.IconExchange,
            breadcrumbs: false
        },
        {
            id: 'liquidations',
            title: <FormattedMessage id="Liquidations" />,
            type: 'item',
            url: '/liquidations',
            icon: icons.IconAmbulance,
            breadcrumbs: false
        },
        {
            id: 'noteToken',
            title: <FormattedMessage id="NOTE Token" />,
            type: 'item',
            url: '/note-token',
            icon: icons.IconBusinessplan,
            breadcrumbs: false
        },
        {
            id: 'treasury',
            title: <FormattedMessage id="Treasury" />,
            type: 'item',
            url: '/treasury',
            icon: icons.IconZoomMoney,
            breadcrumbs: false
        },
        {
            id: 'sNOTE',
            title: <FormattedMessage id="sNOTE" />,
            type: 'item',
            url: '/s-note',
            icon: icons.IconMeat,
            breadcrumbs: false
        },
        {
            id: 'exportHistoricalData',
            title: <FormattedMessage id="Export Historical Data" />,
            type: 'item',
            url: '/historical-data',
            icon: icons.IconArrowBarToDown,
            breadcrumbs: false
        },
        {
            id: 'leveragedVaults',
            title: <FormattedMessage id="Leveraged Vaults" />,
            type: 'item',
            url: '/leveraged-vaults',
            icon: icons.IconSquareRoundedLetterV,
            breadcrumbs: false
        }
    ]
};

export default homepage;
