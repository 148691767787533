import gql from 'graphql-tag';
import { BlockTimeline } from 'utils/charts';
import { Market } from 'utils/market';
import { NToken, NTOKEN_ACCOUNT_IDS } from 'utils/ntoken';
import { Account } from './account';
import { LeveragedVaults } from 'utils/leveragedVaults';

export const MARKETS_QUERY = gql`
    query markets {
        markets(where: { totalAssetCash_gt: 0 }) {
            id
            lastUpdateTimestamp
            currency {
                id
                underlyingName
                underlyingSymbol
                symbol
                ethExchangeRate {
                    buffer
                    haircut
                }
            }
            maturity
            lastImpliedRate
            marketIndex
            marketMaturityLengthSeconds
            totalLiquidity
            totalfCash
            totalAssetCash
        }
    }
`;

export type MarketsQueryResponse = {
    markets: Array<Market>;
};

export const NTOKEN_QUERY = gql`
    query getNTokens {
        markets(where: { totalAssetCash_gt: 0 }) {
            id
            lastUpdateTimestamp
            currency {
                id
                underlyingName
                underlyingSymbol
                symbol
                ethExchangeRate {
                    buffer
                    haircut
                }
            }
            maturity
            lastImpliedRate
            marketIndex
            marketMaturityLengthSeconds
            totalLiquidity
            totalfCash
            totalAssetCash
        }
        nTokens {
            name
            id
            symbol
            totalSupply
            integralTotalSupply
            pvHaircutPercentage
            currency {
                symbol
                underlyingSymbol
                id
            }
            account {
                id
                balances {
                    assetCashBalance
                    currency {
                        id
                        symbol
                        underlyingName
                        tokenType
                        decimals
                    }
                }
                portfolio {
                    assetType
                    notional
                    maturity
                    currency {
                        id
                        symbol
                        underlyingName
                        tokenType
                        decimals
                    }
                }
            }
        }
    }
`;

export type NTokenQueryResponse = MarketsQueryResponse & {
    nTokens: Array<NToken>;
};

export const CURRENCIES_QUERY = gql`
    {
        currencies {
            id
            underlyingName
            underlyingSymbol
            symbol
            ethExchangeRate {
                rateOracle
                rateDecimalPlaces
                mustInvert
                buffer
                haircut
                liquidationDiscount
                baseCurrency {
                    underlyingName
                }
            }
            assetExchangeRate {
                rateAdapterAddress
                underlyingDecimalPlaces
            }
            cashGroup {
                maxMarketIndex
                rateOracleTimeWindowSeconds
                totalFeeBasisPoints
                reserveFeeSharePercent
                debtBufferBasisPoints
                fCashHaircutBasisPoints
                liquidityTokenHaircutsPercent
                rateScalars
            }
            nToken {
                tokenAddress
                name
                symbol
                decimals
                depositShares
                leverageThresholds
                incentiveEmissionRate
                pvHaircutPercentage
                annualizedAnchorRates
            }
        }
    }
`;

export type CurrenciesQueryResponse = {
    currencies: Currency[];
};

export type Currency = {
    id: string;
    underlyingName: string;
    underlyingSymbol: string;
    symbol: string;
    ethExchangeRate: {
        rateOracle: string;
        rateDecimalPlaces: number;
        mustInvert: boolean;
        buffer: number;
        haircut: number;
        liquidationDiscount: number;
        baseCurrency: {
            underlyingName: string;
        };
    };
    assetExchangeRate: {
        rateAdapterAddress: string;
        underlyingDecimalPlaces: number;
    };
    cashGroup: {
        maxMarketIndex: number;
        rateOracleTimeWindowSeconds: number;
        totalFeeBasisPoints: number;
        reserveFeeSharePercent: number;
        debtBufferBasisPoints: number;
        fCashHaircutBasisPoints: number;
        liquidityTokenHaircutsPercent: number[];
        rateScalars: number[];
    };
    nToken: {
        tokenAddress: string;
        name: string;
        symbol: string;
        decimals: number;
        depositShares: number;
        leverageThresholds: number[];
        incentiveEmissionRate: string;
        pvHaircutPercentage: number;
        annualizedAnchorRates: number[];
    };
};

export const MARKET_DETAILS_QUERY = gql`
    query getMarketDetails($id: String!) {
        market(id: $id) {
            id
            maturity
            lastImpliedRate
            oracleRate
            marketMaturityLengthSeconds
            settlementDate
            totalfCash
            totalAssetCash
            marketIndex
            currency {
                id
                underlyingName
                underlyingSymbol
                symbol
                ethExchangeRate {
                    rateOracle
                    rateDecimalPlaces
                    mustInvert
                    buffer
                    haircut
                    liquidationDiscount
                    baseCurrency {
                        underlyingName
                    }
                }
                assetExchangeRate {
                    rateAdapterAddress
                    underlyingDecimalPlaces
                }
                cashGroup {
                    maxMarketIndex
                    rateOracleTimeWindowSeconds
                    totalFeeBasisPoints
                    reserveFeeSharePercent
                    debtBufferBasisPoints
                    fCashHaircutBasisPoints
                    liquidityTokenHaircutsPercent
                    rateScalars
                }
                nToken {
                    tokenAddress
                    name
                    symbol
                    decimals
                    depositShares
                    leverageThresholds
                    incentiveEmissionRate
                    pvHaircutPercentage
                    annualizedAnchorRates
                }
            }
        }
        allMarkets: markets {
            id
            marketIndex
            maturity
            currency {
                id
            }
        }
    }
`;

export type MarketDetailsQueryResponse = {
    market: {
        id: string;
        maturity: string;
        lastImpliedRate: string;
        oracleRate: string;
        marketMaturityLengthSeconds: number;
        settlementDate: string;
        totalfCash: string;
        totalAssetCash: string;
        marketIndex: number;
        currency: Currency;
    };
    allMarkets: Array<{
        id: string;
        marketIndex: number;
        maturity: string;
        currency: {
            id: string;
        };
    }>;
};

export const NTOKEN_DETAILS_QUERY = gql`
    query getNTokenDetails($id: String!) {
        markets(where: { totalAssetCash_gt: 0 }) {
            lastUpdateTimestamp
            currency {
                id
                underlyingName
                underlyingSymbol
                symbol
                ethExchangeRate
            }
            maturity
            lastImpliedRate
            marketIndex
            marketMaturityLengthSeconds
            totalLiquidity
            totalfCash
            totalAssetCash
        }
        nToken(id: $id) {
            id
            tokenAddress
            name
            symbol
            decimals
            depositShares
            leverageThresholds
            pvHaircutPercentage
            totalSupply
            proportions
            annualizedAnchorRates
            liquidationHaircutPercentage
            residualPurchaseIncentiveBasisPoints
            residualPurchaseTimeBufferSeconds
            cashWithholdingBufferBasisPoints
            incentiveEmissionRate
            currency {
                id
                symbol
                underlyingSymbol
            }
            account {
                id
                balances {
                    assetCashBalance
                    currency {
                        symbol
                        underlyingName
                        tokenType
                        decimals
                    }
                }
                portfolio {
                    assetType
                    notional
                    maturity
                    currency {
                        symbol
                        underlyingName
                        tokenType
                        decimals
                    }
                }
            }
        }
    }
`;

export type NTokenDetail = NToken & {
    tokenAddress: string;
    decimals: number;
    depositShares: number[];
    leverageThresholds: number[];
    pvHaircutPercentage: number;
    annualizedAnchorRates: number[];
    proportions: number[];
    liquidationHaircutPercentage: number;
    residualPurchaseIncentiveBasisPoints: number;
    residualPurchaseTimeBufferSeconds: number;
    cashWithholdingBufferBasisPoints: number;
    incentiveEmissionRate: number;
};

export type NTokenDetailsQueryResponse = MarketsQueryResponse & {
    nToken: NTokenDetail;
};

export type MarketQueryResponse = {
    lastUpdateTimestamp: number;
    totalAssetCash: number;
    totalLiquidity: number;
    totalfCash: number;
    currency: {
        id: string;
        underlyingName: string;
        underlyingSymbol: string;
    };
    lastImpliedRate: number;
    maturity: number;
    oracleRate: number;
    marketIndex: number;
    marketMaturityLengthSeconds: number;
};

export type MarketsHistoryQueryResponse = {
    [key: string]: MarketQueryResponse[];
};

export function createMarketsHistoryQuery(blockTimeline: BlockTimeline) {
    return gql(
        [
            `{`,
            ...blockTimeline.map(
                ({ timestamp, block }) => `
                t${timestamp}: markets (block: {number: ${block}}) {
                    lastUpdateTimestamp
                    totalAssetCash
                    totalLiquidity
                    totalfCash
                    currency {
                        id
                        underlyingName
                        underlyingSymbol
                    }
                    maturity
                    lastImpliedRate
                    oracleRate
                    marketIndex
                    marketMaturityLengthSeconds
                }`
            ),
            `}`
        ].join('')
    );
}

export type MarketHistoryQueryResponse = {
    [key: string]: MarketQueryResponse;
};

export function createMarketHistoryQuery(blockTimeline: BlockTimeline) {
    return gql(
        [
            `query getMarketHistory($id: String!) {`,
            ...blockTimeline.map(
                ({ timestamp, block }) => `
                    t${timestamp}: market (id: $id, block: {number: ${block}}) {
                        lastUpdateTimestamp
                        totalAssetCash
                        totalLiquidity
                        totalfCash
                        currency {
                            id
                            underlyingName
                            underlyingSymbol
                        }
                        maturity
                        lastImpliedRate
                        oracleRate
                        marketIndex
                        marketMaturityLengthSeconds
                    }`
            ),
            `}`
        ].join('')
    );
}

export function createMarketHistoryQueryV2(blockTimeline: BlockTimeline) {
    return gql(
        [
            `query getMarketHistory($marketIds: [String!]) {`,
            ...blockTimeline.map(
                ({ timestamp, block }) => `
                    t${timestamp}: markets (where: {id_in: $marketIds}, block: {number: ${block}}) {
                        lastUpdateTimestamp
                        totalAssetCash
                        currency {
                            id
                            underlyingName
                            underlyingSymbol
                        }
                        maturity
                        lastImpliedRate
                        oracleRate
                        marketIndex
                        marketMaturityLengthSeconds
                    }`
            ),
            `}`
        ].join('')
    );
}

export type MarketDailyVolume = {
    id: string;
    date: string;
    currency: {
        id: string;
        underlyingSymbol: string;
    };
    market: {
        id: string;
        marketIndex: number;
        maturity: number;
    };
    tradeType: string;
    totalVolumeUnderlyingCash: number;
    totalVolumeNetAssetCash: number;
    totalVolumeNetfCash: number;
};

export const ALL_MARKETS_QUERY = gql`
    query allMarkets {
        markets {
            id
            lastUpdateTimestamp
            currency {
                id
                underlyingName
                underlyingSymbol
                symbol
                ethExchangeRate {
                    buffer
                    haircut
                }
            }
            maturity
            lastImpliedRate
            marketIndex
            marketMaturityLengthSeconds
            totalLiquidity
            totalfCash
            totalAssetCash
        }
    }
`;

export const LEVERAGED_VAULTS_QUERY = gql`
    query leveragedVaults {
        leveragedVaults {
            id
            name
            strategy
            vaultAddress
            primaryBorrowCurrency {
                underlyingSymbol
            }
            secondaryBorrowCurrencies(first: 1) {
                name
                underlyingSymbol
            }
        }
    }
`;

export type LeveragedVaultsQueryReponse = {
    leveragedVaults: Array<LeveragedVaults>;
};

export function createLeveragedVaultCapacityAndUsageQuery(blockTimeline: BlockTimeline) {
    return gql(
        [
            `query getLeveragedVaultCapacityAndUsage($vaultId: String!) {`,
            ...blockTimeline.map(
                ({ timestamp, block }) => `
                    t${timestamp}: leveragedVault(id: $vaultId, block: {number: ${block}} ) {
                        vaultCapacity {
                            maxPrimaryBorrowCapacity
                            maxSecondaryBorrowCapacity
                            totalUsedPrimaryBorrowCapacity
                            totalUsedSecondaryBorrowCapacity
                          }
                          primaryBorrowCurrency {
                            name
                            underlyingSymbol
                          }
                          secondaryBorrowCurrencies {
                            name
                            underlyingSymbol
                          }
                }`
            ),
            `}`
        ].join('')
    );
}

export function createLeveragedVaultFeesQuery(blockTimeline?: BlockTimeline) {
    if (!blockTimeline) {
        return gql(``);
    }

    return gql(
        [
            `query getLeveragedVaultFees($vaultId: String!) {`,
            ...blockTimeline.map(
                ({ timestamp, block }) => `
                    t${timestamp}: leveragedVault(id: $vaultId, block: {number: ${block}} ) {
                        id
                        maturities{
                          totalNTokenFeesAccrued
                          totalReserveFeesAccrued 
                      }
                }`
            ),
            `}`
        ].join('')
    );
}

export type LeveragedVaultFeesReponse = {
    [key: string]: LeveragedVaultFees;
};

export type LeveragedVaultFees = {
    id: string;
    maturities: {
        totalNTokenFeesAccrued: number;
        totalReserveFeesAccrued: number;
    }[];
};

export type SecondaryBorrowCurrencies = {
    name: string;
    underlyingSymbol: string;
};

export type LeverageVaultUsageCapacityQueryResponse = {
    vaultCapacity: LeveragedVautlsCapacity;
    primaryBorrowCurrency: {
        name: string;
        underlyingSymbol: string;
    };
    secondaryBorrowCurrencies: SecondaryBorrowCurrencies[];
};

export type LeveragedVautlsCapacity = {
    maxPrimaryBorrowCapacity: number;
    maxSecondaryBorrowCapacity: number | null;
    totalUsedPrimaryBorrowCapacity: number;
    totalUsedSecondaryBorrowCapacity: number | null;
};

export type LeverageVaultUsageCapacitiesQueryResponse = {
    [key: string]: LeverageVaultUsageCapacityQueryResponse;
};

export const LEVERAGED_VAULTS_DETAILS_QUERY = gql(
    `query getLeveragedVaultCapacityAndUsage($vaultId: String!) {
            leveragedVault(id: $vaultId) {
                enabled
                name
                minAccountBorrowSize 
                minCollateralRatioBasisPoints 
                maxRequiredAccountCollateralRatioBasisPoints
                maxDeleverageCollateralRatioBasisPoints
                liquidationRatePercent
                feeRateBasisPoints
                reserveFeeSharePercent              
                maturities {
                    totalAssetCash
                    totalStrategyTokens                  
                    maturity
                    historicalValue(first: 1, orderBy: timestamp, orderDirection: desc) {
                        underlyingValueOfStrategyToken
                    }
                    shortfall
                    insolvency
                    totalNTokenFeesAccrued
                    totalReserveFeesAccrued 
                }
                vaultCapacity {
                    maxPrimaryBorrowCapacity
                    maxSecondaryBorrowCapacity
                    totalUsedPrimaryBorrowCapacity
                    totalUsedSecondaryBorrowCapacity
                }
                primaryBorrowCurrency {
                    underlyingSymbol
                    underlyingDecimals
                    id
                }
                secondaryBorrowCurrencies(first: 1) {
                    name
                    underlyingSymbol
                }
            }
        }
`
);

export type LeveragedVaultsDetail = {
    leveragedVault: {
        name: string;
        enabled: boolean;
        minAccountBorrowSize: number;
        minCollateralRatioBasisPoints: number;
        maxRequiredAccountCollateralRatioBasisPoints: number;
        maxDeleverageCollateralRatioBasisPoints: number;
        liquidationRatePercent: number;
        feeRateBasisPoints: number;
        reserveFeeSharePercent: number;
        vaultCapacity: LeveragedVautlsCapacity;
        maturities: LeveragedVautlsMaturity[];
        primaryBorrowCurrency: {
            underlyingSymbol: string;
            underlyingDecimals: number;
            id: string;
        };
        secondaryBorrowCurrencies: SecondaryBorrowCurrencies[];
    };
};

export type LeveragedVautlsMaturity = {
    totalAssetCash: number;
    totalStrategyTokens: number;
    shortfall?: number;
    insolvency?: number;
    maturity: number;
    totalNTokenFeesAccrued: number;
    totalReserveFeesAccrued: number;
    historicalValue: [
        {
            underlyingValueOfStrategyToken: number;
        }
    ];
};

export const HISTORICAL_STRATEGY_TOKEN_VALUE_BATCH_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String) {
        batch: leveragedVaultHistoricalValues(first: $pageSize, where: { id_gt: $lastID }, orderBy: id, orderDirection: asc) {
            id
            timestamp
            underlyingValueOfStrategyToken
        }
    }
`;

export const LEVERAGED_VAULT_MATURITIES = gql`
    query leveragedVaultsMaturities {
        leveragedVaultMaturities {
            id
            maturity
            totalVaultShares
            totalStrategyTokens
            totalAssetCash
        }
    }
`;

export type LeveragedVaultsMaturities = {
    leveragedVaultMaturities: LeveragedVaultsMaturity[];
};

export type LeveragedVaultsMaturity = {
    id: string;
    maturity: number;
    totalVaultShares: number;
    totalStrategyTokens: number;
    totalAssetCash: number;
};

export const LEVERAGED_VAULT_ACCOUNTS = gql`
    query leveragedVaultAccounts {
        leveragedVaultAccounts {
            id
            account {
                id
            }
            maturity
            vaultShares
            primaryBorrowfCash
        }
    }
`;

export type LeveragedVaultAccounts = {
    leveragedVaultAccounts: LeveragedVaultAccount[];
};

export type LeveragedVaultAccount = {
    id: string;
    account: {
        id: string;
    };
    maturity: number;
    vaultShares: number;
    primaryBorrowfCash: number;
};

export interface HistoricalStrategyTokenValueQueryResponse {
    batch: HistoricalStrategyTokenValue[];
}

export type HistoricalStrategyTokenValue = {
    timestamp: string;
    underlyingValueOfStrategyToken: number;
    id: string;
};

export const LEVERAGED_VAULT_ACCOUNT_BY_ID = gql`
    query leveragedVaultAccountById($accountId: String!) {
        leveragedVaultAccounts(where: { account_: { id: $accountId } }) {
            id
            account {
                id
            }
            leveragedVault {
                name
                primaryBorrowCurrency {
                    id
                    underlyingSymbol
                }
            }
            leveragedVaultMaturity {
                id
                totalAssetCash
                totalStrategyTokens
                totalVaultShares
                historicalValue(first: 1, orderBy: timestamp, orderDirection: desc) {
                    underlyingValueOfStrategyToken
                }
            }
            trades {
                vaultTradeType
                timestamp
                transactionHash
                netVaultSharesChange
                netPrimaryBorrowfCashChange
            }
            maturity
            vaultShares
            primaryBorrowfCash
        }
    }
`;

export const LEVERAGED_VAULT_ACCOUNT_WITHOUT_MATURITY_BY_ID = gql`
    query leveragedVaultAccountById($accountId: String!) {
        leveragedVaultAccounts(where: { account_: { id: $accountId } }) {
            id
            account {
                id
            }
            leveragedVault {
                name
                primaryBorrowCurrency {
                    id
                    underlyingSymbol
                }
            }
            trades {
                vaultTradeType
                timestamp
                transactionHash
                netVaultSharesChange
                netPrimaryBorrowfCashChange
            }
            maturity
            vaultShares
            primaryBorrowfCash
        }
    }
`;

export type LeveragedVaultAccountDetails = {
    leveragedVaultAccounts: LeveragedVaultAccountDetail[];
};

export type LeveragedVaultAccountDetail = {
    id: string;
    account: {
        id: string;
    };
    leveragedVault: {
        name: string;
        primaryBorrowCurrency: {
            id: number;
            underlyingSymbol: string;
        };
    };
    leveragedVaultMaturity: {
        totalAssetCash: number;
        totalStrategyTokens: number;
        totalVaultShares: number;
        historicalValue: {
            underlyingValueOfStrategyToken: number;
        }[];
    };
    trades: {
        vaultTradeType: string;
        timestamp: number;
        transactionHash: string;
        netVaultSharesChange: number;
        netPrimaryBorrowfCashChange: number;
    }[];
    maturity: number;
    vaultShares: number;
    primaryBorrowfCash: number;
};

export const LEVERAGED_VAULT_MARKET = gql`
    query leveragedVaultMarket($currencyId: String!, $maturity: Int!) {
        markets(first: 1, orderBy: marketIndex, orderDirection: asc, where: { currency_: { id: $currencyId }, maturity: $maturity }) {
            lastImpliedRate
            marketIndex
            currency {
                underlyingSymbol
            }
        }
    }
`;

export const LEVERAGED_VAULT_MARKETS = gql`
    query leveragedVaultMarket($currencyId: String!, $maturities: [Int!]) {
        markets(orderBy: marketIndex, orderDirection: asc, where: { currency_: { id: $currencyId }, maturity_in: $maturities }) {
            maturity
            lastImpliedRate
            marketIndex
            currency {
                underlyingSymbol
            }
        }
    }
`;

export type LeveragedVaultMarkets = {
    markets: LeveragedVaultMarket[];
};

export type LeveragedVaultMarket = {
    maturity: number;
    lastImpliedRate: number;
    marketIndex: number;
    currency: {
        underlyingSymbol: string;
    };
};

export interface VaultTradesBatchQueryResponse {
    batch: VaultTrade[];
}

export interface VaultTrade {
    netPrimaryBorrowfCashChange: number;
    netVaultSharesChange: number;
    vaultTradeType: string;
    transactionHash: string;
    timestamp: number;
    account: {
        id: string;
    };
    leveragedVaultAccount: {
        maturity: number;
    };
    leveragedVaultMaturityBefore: {
        maturity: number;
    };
    leveragedVaultMaturityAfter: {
        maturity: number;
    };
    leveragedVault: {
        id: string;
        name: string;
        primaryBorrowCurrency: {
            underlyingSymbol: string;
            id: string;
        };
    };
}

export const VAULT_TRADES_BATCH_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String) {
        batch: leveragedVaultTrades(first: $pageSize, where: { id_gt: $lastID }, orderBy: id, orderDirection: asc) {
            id
            netPrimaryBorrowfCashChange
            netVaultSharesChange
            vaultTradeType
            transactionHash
            timestamp
            leveragedVaultMaturityBefore {
                maturity
            }
            leveragedVaultMaturityAfter {
                maturity
            }
            leveragedVaultAccount {
                maturity
            }
            account {
                id
            }
            leveragedVaultMaturityAfter {
                maturity
            }
            leveragedVault {
                id
                name
                primaryBorrowCurrency {
                    underlyingSymbol
                    id
                }
            }
        }
    }
`;

export const HISTORICAL_VOLUME_TRADES_BATCH_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String) {
        batch: leveragedVaultTrades(first: $pageSize, where: { id_gt: $lastID }, orderBy: id, orderDirection: asc) {
            id
            leveragedVault {
                primaryBorrowCurrency {
                    underlyingSymbol
                    id
                }
            }
            timestamp
            vaultTradeType
            netPrimaryBorrowfCashChange
            primaryBorrowfCashAfter
            primaryBorrowfCashBefore
            leveragedVaultMaturityBefore {
                maturity
            }
            leveragedVaultMaturityAfter {
                maturity
            }
        }
    }
`;

export type HistoricalVolumeVaultTrade = {
    leveragedVault: {
        primaryBorrowCurrency: {
            id: string;
            underlyingSymbol: string;
        };
    };
    timestamp: number;
    vaultTradeType: string;
    netPrimaryBorrowfCashChange: number;
    primaryBorrowfCashAfter: number;
    primaryBorrowfCashBefore: number;
    leveragedVaultMaturityBefore: {
        maturity: number;
    };
    leveragedVaultMaturityAfter: {
        maturity: number;
    };
};

export type LeveragedVaultLiquidations = {
    leveragedVaultTrades: {
        netUnderlyingCash: number;
        vaultTradeType: string;
        transactionHash: string;
        timestamp: number;
        account: {
            id: string;
        };
    }[];
};

export const LEVERAGED_VAULT_LIQUIDATION = gql`
    query LeveragedVaultLiquidations($vaultId: String!) {
        leveragedVaultTrades(where: { vaultTradeType: "DeleverageAccount", leveragedVault_: { id: $vaultId } }) {
            netUnderlyingCash
            vaultTradeType
            transactionHash
            timestamp
            leveragedVault {
                id
            }
            account {
                id
            }
        }
    }
`;

export const LEVERAGED_VAULT_ASSET_EXCHANGE_RATE = gql`
    query LeveragedVaultAssetExchangeRate($currency: String!) {
        assetExchangeRateHistoricalDatas(
            first: 1
            orderBy: timestamp
            orderDirection: desc
            where: { currency_: { underlyingSymbol: $currency } }
        ) {
            value
            currency {
                underlyingSymbol
            }
        }
    }
`;

export type LeveragedVaultAssetExchangeRate = {
    assetExchangeRateHistoricalDatas: {
        value: number;
    }[];
};

export const ETH_TO_USD_EXCHANGE_RATE = gql`
    {
        ethExchangeRateHistoricalDatas(first: 1, orderBy: timestamp, orderDirection: desc, where: { currency_: { id: 3 } }) {
            value
        }
    }
`;

export type ETHToUSDExchangeRate = {
    ethExchangeRateHistoricalDatas: ExchangeRate[];
};

export type ExchangeRate = {
    value: number;
};

export const HISTORICAL_VOLUME_QUERY = gql`
    query getHistoricalVolume {
        dailyLendBorrowVolumes(first: 1000, orderBy: date) {
            id
            date
            currency {
                id
                underlyingSymbol
            }
            market {
                id
                marketIndex
            }
            tradeType
            totalVolumeUnderlyingCash
            totalVolumeNetAssetCash
            totalVolumeNetfCash
        }
    }
`;

export const HISTORICAL_VOLUME_BATCH_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String) {
        batch: dailyLendBorrowVolumes(first: $pageSize, where: { id_gt: $lastID }, orderBy: id, orderDirection: asc) {
            id
            date
            currency {
                id
                underlyingSymbol
            }
            market {
                id
                marketIndex
                maturity
            }
            tradeType
            totalVolumeUnderlyingCash
            totalVolumeNetAssetCash
            totalVolumeNetfCash
        }
    }
`;

export type MarketHistoricalVolumeQueryResponse = {
    dailyLendBorrowVolumes: Array<MarketDailyVolume>;
};

export const MARKET_HISTORICAL_VOLUME_QUERY = gql`
    query getMarketHistoricalVolume($marketIds: [String!]) {
        dailyLendBorrowVolumes(first: 1000, orderBy: date, where: { market_in: $marketIds }) {
            id
            date
            currency {
                id
                underlyingSymbol
            }
            market {
                id
                marketIndex
            }
            tradeType
            totalVolumeUnderlyingCash
            totalVolumeNetAssetCash
            totalVolumeNetfCash
        }
    }
`;

export const MARKET_HISTORICAL_VOLUME_BATCH_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String, $marketIds: [String!]) {
        batch: dailyLendBorrowVolumes(
            first: $pageSize
            where: { id_gt: $lastID, market_in: $marketIds }
            orderBy: id
            orderDirection: asc
        ) {
            id
            date
            currency {
                id
                underlyingSymbol
            }
            market {
                id
                marketIndex
                maturity
            }
            tradeType
            totalVolumeUnderlyingCash
            totalVolumeNetAssetCash
            totalVolumeNetfCash
        }
    }
`;

export type NTokenComposition = {
    currency: {
        id: string;
        underlyingSymbol: string;
    };
    marketIndex: string;
    marketMaturityLengthSeconds: number;
    maturity: number;
    lastImpliedRate: number;
    totalAssetCash: number;
    totalLiquidity: number;
    totalfCash: number;
};

export type NTokenCompositionQueryResponse = {
    markets: Array<NTokenComposition>;
};

export const NTOKEN_COMPOSITION_QUERY = gql`
    query getMarketComposition($currencyId: String!) {
        markets(where: { currency: $currencyId }) {
            totalfCash
            totalAssetCash
            totalLiquidity
            marketIndex
            marketMaturityLengthSeconds
            maturity
            lastImpliedRate
            currency {
                id
                underlyingSymbol
            }
        }
    }
`;

export type AllNTokenHoldingsQueryResponse = NTokenCompositionQueryResponse & {
    nTokens: NTokenDetail[];
};

export const ALL_NTOKEN_DETAILS_QUERY = gql`
    query getMarketComposition {
        markets(where: { totalAssetCash_gt: 0 }) {
            totalfCash
            totalAssetCash
            totalLiquidity
            marketIndex
            marketMaturityLengthSeconds
            maturity
            lastImpliedRate
            currency {
                id
                underlyingSymbol
            }
        }
        nTokens {
            id
            tokenAddress
            name
            symbol
            decimals
            depositShares
            leverageThresholds
            pvHaircutPercentage
            totalSupply
            proportions
            annualizedAnchorRates
            liquidationHaircutPercentage
            residualPurchaseIncentiveBasisPoints
            residualPurchaseTimeBufferSeconds
            cashWithholdingBufferBasisPoints
            incentiveEmissionRate
            currency {
                id
                symbol
                underlyingSymbol
            }
            account {
                id
                balances {
                    assetCashBalance
                    currency {
                        symbol
                        underlyingName
                        tokenType
                        decimals
                    }
                }
                portfolio {
                    assetType
                    notional
                    maturity
                    currency {
                        symbol
                        underlyingName
                        tokenType
                        decimals
                    }
                }
            }
        }
    }
`;

export type CurrencyTVL = {
    id: string;
    currency: {
        underlyingSymbol: string;
    };
    underlyingValue: string;
    usdValue: string;
};

export type CompBalance = {
    id: string;
    value: string;
    usdValue: string;
};

export type TvlHistoricalData = {
    id: string;
    timestamp: string;
    usdTotal: number;
    compBalance: CompBalance | null;
    perCurrencyTvl: CurrencyTVL[];
};

export type TotalValueLockedHistoryQueryResponse = {
    tvlHistoricalDatas: Array<TvlHistoricalData>;
};

export const TOTAL_VALUE_LOCKED_HISTORY_QUERY = gql`
    query getTotalValueLockedHistoryQuery {
        tvlHistoricalDatas(first: 1000, orderBy: timestamp, orderDirection: asc, where: { timestamp_gt: 1635724800 }) {
            id
            timestamp
            usdTotal
            perCurrencyTvl(first: 1000) {
                id
                currency {
                    underlyingSymbol
                }
                underlyingValue
                usdValue
            }
            compBalance {
                id
                value
                usdValue
            }
        }
    }
`;

export const LATEST_TOTAL_VALUE_LOCKED_QUERY = gql`
    query getLatestTotalValueLockedQuery {
        tvlHistoricalDatas(first: 1, orderBy: timestamp, orderDirection: desc) {
            id
            timestamp
            usdTotal
        }
    }
`;

export type NTokenSupplyHistoryQueryResponse = {
    [key: string]: {
        totalSupply: number;
        symbol: string;
    };
};

export function createNTokenSupplyHistoryQuery(blockTimeline: BlockTimeline) {
    return gql(
        [
            `query getNTokenSupplyHistory($id: String!) {`,
            ...blockTimeline.map(
                ({ timestamp, block }) => `
                t${timestamp}: nToken (id: $id, block: {number: ${block}}) {
                    totalSupply
                    symbol
                }`
            ),
            `}`
        ].join('')
    );
}

export type NTokenNetPresentValueHistoryQueryResponse = {
    [key: string]: {
        nTokenBalanceAfter: string | null;
        nTokenBalanceBefore: string | null;
        nTokenValueUnderlyingBefore: string | null;
        nTokenValueUnderlyingAfter: string | null;
    }[];
};

export function createNTokenNetPresentValueHistoryQuery(blockTimeline: BlockTimeline) {
    return gql(
        [
            `query getNTokenNetPresentValueHistory($currencyId: String!) {`,
            ...blockTimeline.map(
                ({ timestamp, block }) => `
                afterBalanceT${timestamp}: balanceChanges(first: 1, where: {currency: $currencyId,  timestamp_gt: ${timestamp}, nTokenBalanceAfter_gt: 0}) {
                    nTokenBalanceAfter
                    nTokenValueUnderlyingAfter
                }`
            ),
            ...blockTimeline.map(
                ({ timestamp, block }) => `
                beforeBalanceT${timestamp}: balanceChanges(first: 1, where: {currency: $currencyId,  timestamp_gt: ${timestamp}, nTokenBalanceBefore_gt: 0}) {
                    nTokenBalanceBefore
                    nTokenValueUnderlyingBefore
                }`
            ),
            `}`
        ].join('')
    );
}

export type AccountDetailsQueryResponse = NTokenQueryResponse & {
    account: Account;
};

export const ACCOUNT_DETAILS_QUERY = gql`
    query getAccountDetails($id: String!) {
        markets(where: { totalAssetCash_gt: 0 }) {
            id
            lastUpdateTimestamp
            currency {
                id
                underlyingName
                underlyingSymbol
                symbol
                ethExchangeRate {
                    buffer
                    haircut
                }
            }
            maturity
            lastImpliedRate
            marketIndex
            marketMaturityLengthSeconds
            totalLiquidity
            totalfCash
            totalAssetCash
        }
        nTokens {
            name
            id
            symbol
            totalSupply
            integralTotalSupply
            pvHaircutPercentage
            currency {
                symbol
            }
            account {
                id
                balances {
                    assetCashBalance
                    currency {
                        symbol
                        underlyingName
                        tokenType
                        decimals
                    }
                }
                portfolio {
                    assetType
                    notional
                    maturity
                    currency {
                        symbol
                        underlyingName
                        tokenType
                        decimals
                    }
                }
            }
        }
        account(id: $id) {
            id
            hasCashDebt
            hasPortfolioAssetDebt
            nextSettleTime
            balances {
                assetCashBalance
                nTokenBalance
                currency {
                    symbol
                    underlyingName
                    tokenType
                    decimals
                }
            }
            portfolio {
                assetType
                notional
                maturity
                currency {
                    symbol
                    underlyingName
                    tokenType
                    decimals
                }
            }
        }
    }
`;

export type AccountTradesHistoryQueryResponse = MarketsQueryResponse & {
    account: {
        tradeHistory: Trade[];
        balanceChanges: BalanceChange[];
    };
};

export type BalanceChange = {
    id: string;
    transactionHash: string;
    timestamp: number;
    account: {
        id: string;
    };
    currency: {
        symbol: string;
    };
    assetCashBalanceBefore: number;
    assetCashBalanceAfter: number;
    nTokenBalanceBefore: number;
    nTokenBalanceAfter: number;
};

export type Trade = {
    id: string;
    transactionHash: string;
    timestamp: number;
    maturity: string;
    account: {
        id: string;
    };
    currency: {
        symbol: string;
    };
    market: {
        id: string;
    };
    netAssetCash: string;
    netUnderlyingCash: string;
    netfCash: string;
    tradeType: string;
};

export const ACCOUNT_TRADES_HISTORY = gql`
    query getAccountTradesHistory($id: String!) {
        markets {
            id
            lastUpdateTimestamp
            currency {
                id
                underlyingName
                underlyingSymbol
                symbol
                ethExchangeRate {
                    buffer
                    haircut
                }
            }
            maturity
            lastImpliedRate
            marketIndex
            marketMaturityLengthSeconds
            totalLiquidity
            totalfCash
            totalAssetCash
        }
        account(id: $id) {
            balanceChanges(first: 1000, orderBy: timestamp, orderDirection: desc) {
                transactionHash
                account {
                    id
                }
                currency {
                    symbol
                }
                timestamp
                nTokenBalanceBefore
                nTokenBalanceAfter
            }
            tradeHistory(first: 1000, orderBy: timestamp, orderDirection: desc) {
                transactionHash
                timestamp
                blockNumber
                maturity
                account {
                    id
                }
                currency {
                    symbol
                }
                market {
                    id
                }
                tradeType
                netAssetCash
                netUnderlyingCash
                netUnderlyingCash
                netfCash
            }
        }
    }
`;

export type RecentTransactionsQueryResponse = MarketsQueryResponse & {
    balanceChanges: BalanceChange[];
    trades: Trade[];
};

export interface BalanceChangesBatchQueryResponse {
    batch: BalanceChange[];
}

export const BALANCE_CHANGES_BATCH_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String) {
        batch: balanceChanges(
            first: $pageSize,
            where: { id_gt: $lastID, account_not_in: [
                    ${NTOKEN_ACCOUNT_IDS.map((accountId) => `"${accountId}"`).join(',')}
                ] },
            orderBy: id, orderDirection: asc
        ) {
            id
            transactionHash
            account {
                id
            }
            currency {
                symbol
            }
            timestamp
            nTokenBalanceBefore
            nTokenBalanceAfter
        }
    }
`;

export interface TradesBatchQueryResponse {
    batch: Trade[];
}

export const TRADES_BATCH_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String) {
        batch: trades(
            first: $pageSize,
            where: { id_gt: $lastID, account_not_in: [
                    ${NTOKEN_ACCOUNT_IDS.map((accountId) => `"${accountId}"`).join(',')}
                ] },
            orderBy: id, orderDirection: asc
        ) {
            id
            transactionHash
            timestamp
            blockNumber
            maturity
            account {
                id
            }
            currency {
                symbol
            }
            market {
                id
            }
            tradeType
            netAssetCash
            netUnderlyingCash
            netUnderlyingCash
            netfCash
        }
    }
`;

export type Liquidation = {
    id: string;
    type: string;
    transactionHash: string;
    timestamp: number;
    netLocalFromLiquidator: string;
    account: {
        id: string;
    };
    liquidator: {
        id: string;
    };
    localCurrency: {
        id: string;
        underlyingSymbol: string;
        underlyingDecimals: string;
    };
    collateralOrFcashCurrency: {
        underlyingSymbol: string;
    };
    netCollateralTransfer: number | null;
    netNTokenTransfer: number | null;
    fCashMaturities: number[] | null;
    fCashNotionalTransfer: number[] | null;
};

export type HistoricalData = {
    id: string;
    value: string;
    timestamp: number;
    currency: {
        underlyingSymbol: string;
        underlyingDecimals: number | null;
    };
};

export type RecentLiquidationsQueryResponse = {
    liquidations: Liquidation[];
};

export const RECENT_LIQUIDATIONS = gql`
    {
        liquidations(first: 1000, orderBy: timestamp, orderDirection: desc) {
            id
            type
            transactionHash
            timestamp
            netLocalFromLiquidator
            account {
                id
            }
            liquidator {
                id
            }
            localCurrency {
                id
                underlyingSymbol
                underlyingDecimals
            }
            collateralOrFcashCurrency {
                underlyingSymbol
            }
            netCollateralTransfer
            netNTokenTransfer
            fCashMaturities
            fCashNotionalTransfer
        }
    }
`;

export type ExchangeRatesQueryResponse = {
    ethExchangeRateHistoricalDatas: HistoricalData[];
    assetExchangeRateHistoricalDatas: HistoricalData[];
};

export const EXCHANGE_RATES_QUERY = gql`
    query getLiquidationsExchangeRates($ethExchangeRateIds: [String!], $assetExchangeRateIds: [String!]) {
        ethExchangeRateHistoricalDatas(first: 1000, where: { id_in: $ethExchangeRateIds }) {
            id
            value
            timestamp
            currency {
                underlyingSymbol
            }
        }
        assetExchangeRateHistoricalDatas(first: 1000, where: { id_in: $assetExchangeRateIds }) {
            id
            value
            timestamp
            currency {
                underlyingSymbol
            }
        }
    }
`;

export interface BatchExchangeRateQueryResponse {
    batch: HistoricalData[];
}

export const ASSET_EXCHANGE_RATE_BATCH_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String, $assetExchangeRateIds: [String!]) {
        batch: assetExchangeRateHistoricalDatas(
            first: $pageSize
            where: { id_gt: $lastID, id_in: $assetExchangeRateIds }
            orderBy: id
            orderDirection: asc
        ) {
            id
            value
            timestamp
            currency {
                underlyingSymbol
            }
        }
    }
`;

export const ETH_EXCHANGE_RATE_BATCH_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String, $ethExchangeRateIds: [String!]) {
        batch: ethExchangeRateHistoricalDatas(
            first: $pageSize
            where: { id_gt: $lastID, id_in: $ethExchangeRateIds }
            orderBy: id
            orderDirection: asc
        ) {
            id
            value
            timestamp
            currency {
                underlyingSymbol
            }
        }
    }
`;

export const ASSET_EXCHANGE_RATES_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String) {
        batch: assetExchangeRateHistoricalDatas(first: $pageSize, where: { id_gt: $lastID }, orderBy: id, orderDirection: asc) {
            id
            value
            timestamp
            currency {
                underlyingSymbol
                underlyingDecimals
            }
        }
    }
`;

export const ETH_EXCHANGE_RATES_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String) {
        batch: ethExchangeRateHistoricalDatas(first: $pageSize, where: { id_gt: $lastID }, orderBy: id, orderDirection: asc) {
            id
            value
            timestamp
            currency {
                underlyingSymbol
            }
        }
    }
`;

export const ACTIVE_MARKET_LATEST_TRANSACTIONS = gql`
    query getActiveMarketRecentTransaction($marketIds: [String!]) {
        markets (where: {id_in: $marketIds}) {
            id
            lastUpdateTimestamp
            currency {
                id
                underlyingName
                underlyingSymbol
                symbol
                ethExchangeRate {
                    buffer
                    haircut
                }
            }
            maturity
            lastImpliedRate
            marketIndex
            marketMaturityLengthSeconds
            totalLiquidity
            totalfCash
            totalAssetCash
        }
        trades(
            first: 10
            orderBy: timestamp
            orderDirection: desc
            where: {
                account_not_in: [
                    ${NTOKEN_ACCOUNT_IDS.map((accountId) => `"${accountId}"`).join(',')}
                ],
                market_in: $marketIds
            }
        ) {
            transactionHash
            timestamp
            blockNumber
            maturity
            account {
                id
            }
            currency {
                symbol
            }
            market {
                id
            }
            tradeType
            netAssetCash
            netUnderlyingCash
            netUnderlyingCash
            netfCash
        }
    }
`;

export type EthPriceQueryResponse = {
    bundles: Array<{
        id: string;
        ethPriceUSD: number;
    }>;
};

export const ETH_PRICE_QUERY = gql`
    query allBundles {
        bundles {
            id
            ethPriceUSD
        }
    }
`;

export type GasPriceQueryResponse = {
    transactions: Array<{
        gasPrice: string;
        timestamp: number;
    }>;
};

export const GAS_PRICE_QUERY = gql`
    query gasPriceQuery($timestamp: String!) {
        transactions(first: 1000, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: $timestamp }) {
            gasPrice
            timestamp
        }
    }
`;

export type BlocksTimelineQueryResponse = {
    [key: string]: Array<{
        number: number;
    }>;
};
export function buildBlocksTimelineQuery(timeline: number[]) {
    // Inspired by https://blocklytics.org/blog/time-series-data-using-subgraphs/
    // Query for Blocklytics Ethereum Blocks subgraph https://thegraph.com/hosted-service/subgraph/blocklytics/ethereum-blocks
    return gql(
        [
            `query getBlocksTimeline {`,
            ...timeline.map(
                (timestamp) => `
                t${timestamp}: blocks ( first: 1,
                    orderBy: number,
                    orderDirection: asc,
                    where: {
                        timestamp_gte: "${timestamp}",
                        timestamp_lt: "${timestamp + 600}"
                    }) {
                        number
                    }`
            ),
            `}`
        ].join('')
    );
}

export interface StakedNoteInvestmentQueryResponse {
    stakedNoteInvestments: Array<{
        id: string;
        blockHash: string;
        blockNumber: number;
        timestamp: number;
        transactionHash: string;
        bptPerSNOTEBefore: string;
        bptPerSNOTEAfter: string;
        totalETHInvested: string;
        totalNOTEInvested: string;
        totalSNOTESupply: string;
    }>;
}

export const STAKED_NOTE_INVESTMENTS_QUERY = gql`
    query getStakedNoteInvestments {
        stakedNoteInvestments(first: 1000) {
            id
            blockHash
            blockNumber
            timestamp
            transactionHash
            bptPerSNOTEBefore
            bptPerSNOTEAfter
            totalETHInvested
            totalNOTEInvested
            totalSNOTESupply
        }
    }
`;

export type GovernanceParametersQueryResponse = {
    nTokens: Array<
        NTokenDetail & {
            cashGroup: {
                rateOracleTimeWindowSeconds: number;
                totalFeeBasisPoints: number;
                reserveFeeSharePercent: number;
                debtBufferBasisPoints: number;
                fCashHaircutBasisPoints: number;
                settlementPenaltyRateBasisPoints: number;
                liquidationfCashHaircutBasisPoints: number;
                liquidationDebtBufferBasisPoints: number;
                liquidityTokenHaircutsPercent: number[];
                rateScalars: number[];
            };
        }
    >;
};

export const GOVERNANCE_PARAMETERS_QUERY = gql`
    {
        nTokens {
            id
            tokenAddress
            name
            symbol
            decimals
            depositShares
            leverageThresholds
            pvHaircutPercentage
            totalSupply
            proportions
            annualizedAnchorRates
            liquidationHaircutPercentage
            residualPurchaseIncentiveBasisPoints
            residualPurchaseTimeBufferSeconds
            cashWithholdingBufferBasisPoints
            incentiveEmissionRate
            currency {
                id
                symbol
                underlyingSymbol
            }
            cashGroup {
                rateOracleTimeWindowSeconds
                totalFeeBasisPoints
                reserveFeeSharePercent
                debtBufferBasisPoints
                fCashHaircutBasisPoints
                settlementPenaltyRateBasisPoints
                liquidationfCashHaircutBasisPoints
                liquidationDebtBufferBasisPoints
                liquidityTokenHaircutsPercent
                rateScalars
            }
        }
    }
`;

export type TreasuryTokenTrade = {
    id: string;
    timestamp: number;
    transactionHash: string;
    takerAddress: string;
    makerAsset: {
        symbol: string;
    };
    makerAssetFilledAmount: number;
    takerAssetFilledAmount: number;
    oraclePrice: number;
};

export type IncentiveTransactionsQueryResponse = {
    treasuryTokenTrades: TreasuryTokenTrade[];
};

export const INCENTIVE_TRANSACTIONS_QUERY = gql`
    {
        treasuryTokenTrades(first: 1000, orderBy: timestamp, orderDirection: asc) {
            timestamp
            transactionHash
            makerAsset {
                symbol
            }
            takerAddress
            makerAssetFilledAmount
            takerAssetFilledAmount
            oraclePrice
        }
    }
`;

export type StakedNoteTvl = {
    id: string;
    poolETHBalance: number;
    poolNOTEBalance: number;
    spotPrice: number;
    sNOTETotalSupply: number;
    timestamp: number;
};

export type StakedNoteTvlsQueryResponse = {
    stakedNoteTvls: Array<StakedNoteTvl>;
};

export const STAKED_NOTE_TVLS_QUERY = gql`
    {
        stakedNoteTvls(first: 1000, orderBy: timestamp, orderDirection: asc) {
            id
            poolETHBalance
            poolNOTEBalance
            spotPrice
            timestamp
            sNOTETotalSupply
        }
    }
`;

export type TreasuryParametersQueryResponse = {
    treasury: {
        activeManager: {
            id: string;
        };
        investmentCoolDownInSeconds: number | null;
    };
    treasuryManagerTradingLimits: Array<{
        id: string;
        symbol: string;
        slippageLimit: number;
    }>;
};

export const TREASURY_PARAMETERS_QUERY = gql`
    {
        treasury(id: "0") {
            activeManager {
                id
            }
            investmentCoolDownInSeconds
        }
        treasuryManagerTradingLimits {
            id
            symbol
            slippageLimit
        }
    }
`;

export type ReserveThresholdsQueryResponse = {
    cashGroups: Array<{
        currency: {
            id: string;
            underlyingDecimals: number;
            underlyingSymbol: string;
        };
        lastUpdateTimestamp: number;
        reserveBuffer: number;
    }>;
};

export const RESERVE_THRESHOLDS_QUERY = gql`
    {
        cashGroups {
            currency {
                id
                underlyingDecimals
                underlyingSymbol
            }
            lastUpdateTimestamp
            reserveBuffer
        }
    }
`;

export type NoteBalanceQueryResponse = {
    id: string;
    noteBalance: number;
};

export type NoteBalancesQueryResponse = {
    [key: string]: NoteBalanceQueryResponse;
};

export const NOTE_BALANCES_QUERY = gql`
    {
        liquidityOne: noteBalance(id: "0x086b4ecd75c494dd36641195e89c25373e06d7cb") {
            id
            noteBalance
        }
        liquidityTwo: noteBalance(id: "0x1344a36a1b56144c3bc62e7757377d288fde0369") {
            id
            noteBalance
        }
        grantsOne: noteBalance(id: "0x1cea53ce0cf271a600ca05c5ba97bdf473d23450") {
            id
            noteBalance
        }
        grantsTwo: noteBalance(id: "0xfe4202c3f6de22068de5f3b463ae386dccab3e2a") {
            id
            noteBalance
        }
    }
`;

export type OverViewQueryResponse = {
    cashGroups: CashGroup[];
    liquidityOne: NoteBalanceQueryResponse;
    liquidityTwo: NoteBalanceQueryResponse;
    grantsOne: NoteBalanceQueryResponse;
    grantsTwo: NoteBalanceQueryResponse;
    tvlHistoricalDatas: Array<{
        id: string;
        timestamp: number;
        compBalance: CompBalance;
    }>;
};

export const TREASURY_OVERVIEW_QUERY = gql`
    {
        cashGroups {
            id
            lastUpdateTimestamp
            currency {
                id
                symbol
                underlyingSymbol
                underlyingDecimals
            }
            reserveBalance
        }
        liquidityOne: noteBalance(id: "0x086b4ecd75c494dd36641195e89c25373e06d7cb") {
            id
            noteBalance
        }
        liquidityTwo: noteBalance(id: "0x1344a36a1b56144c3bc62e7757377d288fde0369") {
            id
            noteBalance
        }
        grantsOne: noteBalance(id: "0x1cea53ce0cf271a600ca05c5ba97bdf473d23450") {
            id
            noteBalance
        }
        grantsTwo: noteBalance(id: "0xfe4202c3f6de22068de5f3b463ae386dccab3e2a") {
            id
            noteBalance
        }
        tvlHistoricalDatas(first: 1, orderBy: timestamp, orderDirection: desc) {
            id
            timestamp
            compBalance {
                id
                value
                usdValue
            }
        }
    }
`;

export type NotePerNToken = {
    id: string;
    timestamp: number;
    accumulatedNOTEPerNTokenAfter: number;
    nToken: {
        currency: {
            underlyingSymbol: string;
        };
    };
};

export interface NotePerNTokenQueryResponse {
    batch: NotePerNToken[];
}

export const NOTE_PER_NTOKEN_BATCH_QUERY = gql`
    query batchQuery($pageSize: Int!, $lastID: String) {
        batch: nTokenChanges(first: $pageSize, where: { id_gt: $lastID, timestamp_gt: 1648300209 }, orderBy: id, orderDirection: asc) {
            id
            timestamp
            accumulatedNOTEPerNTokenAfter
            nToken {
                currency {
                    underlyingSymbol
                }
            }
        }
    }
`;

export type CashGroup = {
    id: string;
    lastUpdateTimestamp: number;
    currency: {
        id: string;
        symbol: string;
        underlyingSymbol: string;
        underlyingDecimals: string;
    };
    reserveBalance: string;
};

export type HistoricalProtocolReservesQueryResponse = {
    [key: string]: CashGroup[];
};

export function createHistoricalProtocolReservesQuery(blockTimeline: BlockTimeline) {
    return gql(
        [
            `query getProtocolReserves {`,
            ...blockTimeline.map(
                ({ timestamp, block }) => `
                t${timestamp}: cashGroups(block: {number: ${block}}) {
                    id
                    lastUpdateTimestamp
                    currency {
                        id
                        symbol
                        underlyingSymbol
                        underlyingDecimals
                    }
                    reserveBalance
                }`
            ),
            `}`
        ].join('')
    );
}
