import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client';
import { CircularProgress, Divider, Grid, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { useState } from 'react';
import { format } from 'utils/format';
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import { EthPriceQueryResponse, ETH_PRICE_QUERY, GasPriceQueryResponse, GAS_PRICE_QUERY } from 'utils/graphql';
import { chunk, meanBy } from 'lodash';

const uniSwapClient = new ApolloClient({
    cache: new InMemoryCache(),
    uri: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3'
});

const gasAmountPerAction = {
    borrow: 592356,
    lend: 398705,
    mint: 440290
};

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: '12px',
        borderRadius: '5px',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary[100],
        display: 'flex',
        flexDirection: 'column',
        minHeight: '350px'
    },
    loader: {
        padding: '12px',
        borderRadius: '5px',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary[100],
        display: 'flex',
        alignItems: 'center',
        minHeight: '350px'
    },
    flexSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    gasCostsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    gasCostsItem: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '5px'
    },
    header: {
        marginTop: '18px'
    },
    divider: {
        borderColor: theme.palette.primary.main,
        marginTop: '2px',
        marginBottom: '6px'
    },
    geiPriceTimestamp: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '40px'
    }
}));

type GasPrice = {
    gasPriceGwei: number;
    timestamp: number;
} | null;

type GasInfoPaperTemplateProps = {
    ethPriceUsd: number;
    gasPrices: Array<GasPrice>;
    gasCostUsdPerAction: {
        borrow: number;
        lend: number;
        mint: number;
    };
};

function GasInfoPaperTemplate({ ethPriceUsd, gasPrices, gasCostUsdPerAction }: GasInfoPaperTemplateProps) {
    const classes = useStyles();
    const latestGasPrice = gasPrices[gasPrices.length - 1];
    const [position, setPosition] = useState<GasPrice>(latestGasPrice);

    return (
        <Paper elevation={6} className={classes.paper}>
            <div className={classes.flexSpaceBetween}>
                <Typography variant="h5">Ethereum price</Typography>
                <Typography variant="h5">{format(ethPriceUsd, { symbol: 'USD', abbreviate: true, decimals: 2 })}</Typography>
            </div>

            <Typography className={classes.header} variant="h5">
                Gas costs
            </Typography>
            <Divider className={classes.divider} />
            <div className={classes.gasCostsContainer}>
                <div className={classes.gasCostsItem}>
                    <Typography variant="h4">Lend</Typography>
                    <Typography variant="h5">~{format(gasCostUsdPerAction.lend, { symbol: 'USD' })}</Typography>
                </div>
                <div className={classes.gasCostsItem}>
                    <Typography variant="h4">Borrow</Typography>
                    <Typography variant="h5">~{format(gasCostUsdPerAction.borrow, { symbol: 'USD' })}</Typography>
                </div>
                <div className={classes.gasCostsItem}>
                    <Typography variant="h4">Mint</Typography>
                    <Typography variant="h5">~{format(gasCostUsdPerAction.mint, { symbol: 'USD' })}</Typography>
                </div>
            </div>

            <Typography className={classes.header} variant="h5">
                Gas price trend
            </Typography>
            <Divider className={classes.divider} />
            <div className={classes.flexSpaceBetween}>
                <div className={classes.geiPriceTimestamp}>
                    <Typography variant="h4">{position ? `${position.gasPriceGwei} gwei` : ''}</Typography>
                    <Typography variant="h6">{position ? `${moment.unix(position.timestamp).format('HH:mm:ss')}` : ''}</Typography>
                </div>
                <Typography variant="h5">Average</Typography>
            </div>
            <ResponsiveContainer width="100%" height={100}>
                <LineChart
                    width={300}
                    height={100}
                    data={gasPrices}
                    onMouseMove={(mouseMove: any) => {
                        setPosition(gasPrices[mouseMove.activeTooltipIndex]);
                    }}
                    onMouseLeave={() => setPosition(latestGasPrice)}
                >
                    <Tooltip content={<div />} />
                    <Line type="monotone" dataKey="gasPriceGwei" stroke="#607aee" strokeWidth={2} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
}

function usdCostPerAction(gasAmount: number, gasPrice: number, ethUsd: number): number {
    return (gasAmount / 1e9) * gasPrice * ethUsd;
}

export default function GasInfoPaper({ className }: { className?: string }) {
    const classes = useStyles();
    const ethPriceQueryResponse = useQuery<EthPriceQueryResponse>(ETH_PRICE_QUERY, { client: uniSwapClient });
    const gasPriceQueryResponse = useQuery<GasPriceQueryResponse>(GAS_PRICE_QUERY, {
        client: uniSwapClient,
        variables: {
            timestamp: moment().subtract(1, 'days').format('X')
        },
        pollInterval: 60000
    });

    if (!ethPriceQueryResponse.data || !gasPriceQueryResponse.data) {
        return (
            <div className={className}>
                <Paper elevation={6} className={classes.loader}>
                    <Grid container spacing={2} height="100%" justifyContent="center" alignItems="center">
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }

    const ethPriceUsd = Number(ethPriceQueryResponse.data.bundles[0].ethPriceUSD);

    // Get the 1000 last values to cover a wider timespan
    const gasPrices = gasPriceQueryResponse.data.transactions
        .slice()
        .reverse()
        .map((transaction) => ({
            gasPriceGwei: Math.floor(Number(transaction.gasPrice) / 1e9),
            timestamp: transaction.timestamp
        }));

    // Smoothen spikes by using an average of 25 values per point.
    const smoothGasPrices = chunk(gasPrices, 25).map((transactionChunk) => ({
        timestamp: transactionChunk[0].timestamp,
        gasPriceGwei: Math.floor(meanBy(transactionChunk, 'gasPriceGwei'))
    }));

    const gasCostUsdPerAction = {
        borrow: usdCostPerAction(gasAmountPerAction.borrow, smoothGasPrices[smoothGasPrices.length - 1].gasPriceGwei, ethPriceUsd),
        lend: usdCostPerAction(gasAmountPerAction.lend, smoothGasPrices[smoothGasPrices.length - 1].gasPriceGwei, ethPriceUsd),
        mint: usdCostPerAction(gasAmountPerAction.mint, smoothGasPrices[smoothGasPrices.length - 1].gasPriceGwei, ethPriceUsd)
    };

    const templateData = {
        ethPriceUsd,
        gasPrices: smoothGasPrices,
        gasCostUsdPerAction
    };

    return (
        <div className={className}>
            <GasInfoPaperTemplate {...templateData} />
        </div>
    );
}
