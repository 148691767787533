import { PaletteMode } from '@material-ui/core';
import dotenv from 'dotenv';
import { LicenseInfo } from '@mui/x-license-pro';

const appStages = ['staging', 'production'];
type AppStage = typeof appStages[number];

const config: {
    basename: string;
    defaultPath: string;
    fontFamily: string;
    borderRadius: number;
    outlinedFilled: boolean;
    theme: PaletteMode;
    presetColor: string;
    i18n: string;
    appStage: AppStage;
} = {
    // basename: only at build time to set, and Don&apos;t add '/' at end off BASENAME for breadcrumbs, also Don&apos;t put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'dark', // light, dark
    presetColor: 'theme1', // default, theme1, theme2, theme3, theme4, theme5, theme6
    // 'en' - English, 'fr' - French
    i18n: 'en',
    appStage: 'production'
};

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    config.theme = 'dark';
}

const dotEnvConfig = dotenv.config();
if (process.env.BRANCH || process.env.REACT_APP_BRANCH) {
    // Adds support for Netlify branch builds
    // More information: https://docs.netlify.com/configure-builds/environment-variables/#git-metadata
    config.appStage = (process.env.BRANCH || process.env.REACT_APP_BRANCH) === 'develop' ? 'staging' : 'production';
} else if (!dotEnvConfig.error && dotEnvConfig.parsed) {
    const env = dotEnvConfig.parsed!;
    if (env.REACT_APP_STAGE && env.REACT_APP_STAGE in appStages) {
        config.appStage = env.REACT_APP_STAGE;
    }
    if (env.REACT_APP_MUI_DATA_GRID_PRO_LICENSE_KEY) {
        LicenseInfo.setLicenseKey(env.REACT_APP_MUI_DATA_GRID_PRO_LICENSE_KEY);
    }
}

if (!LicenseInfo.getLicenseKey() && process.env.REACT_APP_MUI_DATA_GRID_PRO_LICENSE_KEY) {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATA_GRID_PRO_LICENSE_KEY);
}

export default config;
