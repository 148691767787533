import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const Accounts = Loadable(lazy(() => import('views/accounts')));
const NoteToken = Loadable(lazy(() => import('views/noteToken')));
const Transactions = Loadable(lazy(() => import('views/transactions')));
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const ActiveMarketDetails = Loadable(lazy(() => import('views/dashboard/ActiveMarketDetails')));
const NTokenDetails = Loadable(lazy(() => import('views/dashboard/NTokenDetails')));
const AccountDetails = Loadable(lazy(() => import('views/accounts/AccountDetails')));
const Liquidations = Loadable(lazy(() => import('views/liquidations')));
const Treasury = Loadable(lazy(() => import('views/treasury')));
const Staking = Loadable(lazy(() => import('views/staking')));
const DataExtractor = Loadable(lazy(() => import('views/dataextractor')));
const Vaults = Loadable(lazy(() => import('views/vaults')));
const VaultDetails = Loadable(lazy(() => import('views/vaults/vaultDetails')));
const VaultAccountDetail = Loadable(lazy(() => import('views/vaults/vaultAccountDetail')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard/ntoken/details',
            element: <NTokenDetails />
        },
        {
            path: '/dashboard/active-market/details',
            element: <ActiveMarketDetails />
        },
        {
            path: '/accounts',
            element: <Accounts />
        },
        {
            path: '/accounts/details',
            element: <AccountDetails />
        },
        {
            path: '/transactions',
            element: <Transactions />
        },
        {
            path: '/note-token',
            element: <NoteToken />
        },
        {
            path: '/liquidations',
            element: <Liquidations />
        },
        {
            path: '/s-note',
            element: <Staking />
        },
        {
            path: '/treasury',
            element: <Treasury />
        },
        {
            path: '/historical-data',
            element: <DataExtractor />
        },
        {
            path: '/leveraged-vaults',
            element: <Vaults />
        },
        {
            path: '/leveraged-vaults/:vaultId',
            element: <VaultDetails />
        },
        {
            path: '/leveraged-vaults/:vaultId/account/:accountId',
            element: <VaultAccountDetail />
        }
    ]
};

export default MainRoutes;
