// @ts-ignore
import numberAbbreviate from 'number-abbreviate';
import Notional, { BigNumberType, TypedBigNumber, Currency } from '@notional-finance/sdk-v2';
import { bind } from 'lodash';
import { BigNumber } from 'ethers';

type Options = {
    abbreviate?: boolean;
    symbol?: string;
    decimals?: number;
};

class Formatter {
    private static instance: Formatter;

    private readonly currenciesById: Map<number, Currency>;

    constructor(currencies: Currency[]) {
        this.currenciesById = new Map(currencies.map((currency) => [currency.id, currency]));
    }

    static getInstance(sdk: Notional) {
        const currencies = sdk.system.getAllCurrencies();
        if (!Formatter.instance) {
            Formatter.instance = new Formatter(currencies);
        }
        return Formatter.instance;
    }

    format(value?: number | string | TypedBigNumber, options: Options = { decimals: 2 }) {
        if (value instanceof TypedBigNumber && !options.symbol) {
            const currency = this.currenciesById.get(value.currencyId);
            switch (value.type) {
                case BigNumberType.ExternalUnderlying:
                case BigNumberType.InternalUnderlying:
                    options.symbol = currency?.underlyingSymbol;
                    break;
                case BigNumberType.ExternalAsset:
                case BigNumberType.InternalAsset:
                    options.symbol = currency?.symbol;
                    break;
                case BigNumberType.nToken:
                    options.symbol = currency?.nTokenSymbol;
                    break;
            }
        }
        return format(value, options);
    }
}

/**
 * Returns a formatting function that determines symbol using TypedBigNumber currency when possible.
 * @param sdk
 * @returns
 */
export function getFormatterWithCurrencies(sdk: Notional) {
    const formatter = Formatter.getInstance(sdk);
    return bind(formatter.format, formatter);
}

export function format(value?: number | string | TypedBigNumber, { symbol, decimals, abbreviate }: Options = { decimals: 2 }): string {
    if (value === undefined) {
        return '-';
    }
    if (decimals === undefined) {
        decimals = 2;
    }
    if (value < 1000 && abbreviate) {
        decimals = 0;
    }
    if (typeof value === 'string') {
        value = Number(value);
    }

    if (abbreviate && value instanceof TypedBigNumber) {
        value = Number(value.toExactString());
    }

    if (symbol === 'USD') {
        if (abbreviate && value >= 1000) {
            return `$${numberAbbreviate(value, decimals)}`;
        }
        if (value instanceof TypedBigNumber) {
            return `$${value.toDisplayString(decimals)}`;
        }
        return value.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: decimals });
    }
    if (symbol === '%') {
        if (value instanceof TypedBigNumber) {
            value = Number(value.toExactString());
        }
        return `${(value * 100).toFixed(decimals)}%`;
    }

    let roundedLocalizedValue;
    if (abbreviate && value >= 1000) {
        roundedLocalizedValue = numberAbbreviate(value, decimals);
    } else if (value instanceof TypedBigNumber) {
        roundedLocalizedValue = value.toDisplayString(decimals);
    } else {
        roundedLocalizedValue = value.toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
    }

    if (symbol) {
        return `${roundedLocalizedValue} ${symbol}`;
    }
    return roundedLocalizedValue;
}

export function fromBigNumberToNumber(val: string | null | undefined, bigNumberPrecision: number, numberPrecision: number = 2): number {
    if (!val) {
        return 0;
    }
    return (
        BigNumber.from(val)
            .div(BigNumber.from(10).pow(bigNumberPrecision - numberPrecision))
            .toNumber() / Math.pow(10, numberPrecision)
    );
}
