import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store, persister } from 'store';
// import config from 'config';

// style + assets
import 'assets/scss/style.scss';

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                // This enables support for merging paginated requests.
                // More specifically, it enables querying more than 1000 account in multiple requests and merging everything together.
                // Taken from https://www.apollographql.com/docs/react/pagination/core-api/#defining-a-field-policy
                accounts: {
                    // Don't cache separate results based on
                    // any of this field's arguments.
                    keyArgs: false,
                    // Concatenate the incoming list items with
                    // the existing list items.
                    merge(existing = [], incoming) {
                        return [...existing, ...incoming];
                    }
                }
            }
        }
    }
});

const notionalSubgraphUri = 'https://api.thegraph.com/subgraphs/name/notional-finance/mainnet-v2';
// config.appStage === 'staging'
//     ? 'https://api.thegraph.com/subgraphs/name/notional-finance/kovan-v2'
//     : 'https://api.thegraph.com/subgraphs/name/notional-finance/mainnet-v2';

// ==============================|| REACT DOM RENDER  ||============================== //
const client = new ApolloClient({
    uri: notionalSubgraphUri, // TODO: Move to config.ts, add prod uri
    cache
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persister}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
