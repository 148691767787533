import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: persistReducer(
        {
            key: 'customization',
            storage,
            keyPrefix: 'customization-'
        },
        customizationReducer
    ),
    snackbar: snackbarReducer
});

export default reducer;
